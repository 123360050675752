<template>
  <v-container>
    <h2>Payment Batch</h2>
    <validation-observer v-slot="{ invalid }">
      <v-card-text>
        <v-row>
          <v-col cols="2">
            <v-subheader class="align-center">Payment Date</v-subheader>
          </v-col>
          <v-col cols="3">
            <date-picker
              :value="form.paymentDate"
              :field="{}"
              @fieldChange="onPaymentDateChange"
              :validations="{ required: true }"
            ></date-picker> </v-col
        ></v-row>
        <v-row>
          <v-col cols="2">
            <v-subheader class="align-center">Payer</v-subheader>
          </v-col>
          <v-col cols="3">
            <validation-provider
              :rules="{ required: true }"
              v-slot="{ errors }"
            >
              <v-autocomplete
                v-model="form.payerId"
                :items="payers"
                item-text="name"
                item-value="payerId"
                dense
                :error-messages="errors"
              ></v-autocomplete>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-subheader class="align-center">Payment Method</v-subheader>
          </v-col>
          <v-col cols="3">
            <validation-provider
              :rules="{ required: true }"
              v-slot="{ errors }"
            >
              <v-select
                v-model="form.paymentMethod"
                :items="paymentMethods"
                dense
                :error-messages="errors"
              ></v-select>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-subheader class="align-center">Total Amount</v-subheader>
          </v-col>
          <v-col cols="3">
            <validation-provider
              :rules="validations.totalAmount"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="form.totalAmount"
                type="number"
                filled
                dense
                prepend-icon="mdi-currency-usd"
                :error-messages="errors"
              >
              </v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-subheader class="align-center">Check Number</v-subheader>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="form.checkNumber"
              dense
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-subheader class="align-center">EFT Number</v-subheader>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="form.eftNumber"
              dense
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary"
          :dark="!invalid"
          :disabled="invalid"
          @click="submit"
        >
          {{ submitLabel }}
        </v-btn>
        <v-btn color="primary" text @click="cancel"> Cancel </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </validation-observer>
  </v-container>
</template>
<script>
import { required, minValue } from "../../plugins/vee-validate/validation";
export default {
  props: ["batch"],
  data() {
    return {
      form: this.batch ? this.batch : { paymentDate: new Date() },
    };
  },
  mounted() {
    if (this.batch) {
      this.form.paymentDate = new Date(this.batch.paymentDate);
    }
  },
  computed: {
    validations() {
      return {
        totalAmount: {
          required: true,
          minValue: 0,
        },
      };
    },
    payers() {
      return this.$store.state.optionsModule.payers;
    },
    paymentMethods() {
      return this.$store.state.optionsModule.paymentMethods;
    },
    submitLabel() {
      return this.batch ? "Update Batch" : "Create Batch";
    },
  },
  methods: {
    onPaymentDateChange(value) {
      this.form.paymentDate = value;
    },
    cancel() {
      this.$router.push({ name: "AllBatches" });
    },
    async submit() {
      this.$store.commit("uxModule/setShowLoader", true);
      let message;
      if (this.batch) {
        message = await this.$store.dispatch("paymentBatchModule/updateBatch", {
          ...this.form,
        });
      } else {
        message = await this.$store.dispatch("paymentBatchModule/createBatch", {
          ...this.form,
        });
      }
      this.$store.commit("uxModule/setShowLoader", false);
      if (message) {
        this.$store.commit("uxModule/setSnackbarMsg", message);
        this.$store.commit("uxModule/setShowSnackbar", true);
      } else {
        this.$router.push({ name: "AllBatches" });
      }
    },
  },
};
</script>
