<template>
  <v-dialog v-model="dialog" width="auto">
    <template v-slot:activator="{ on, attrs }">
      <v-tooltip bottom v-bind="attrs" v-on="on">
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <v-btn color="primary" text x-small v-on="on" @click="loadData">
              <v-icon small>mdi-currency-usd</v-icon>
            </v-btn>
          </span>
        </template>
        <span>Payments</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-card-title>
        Associated Payments
        <v-spacer></v-spacer>
        <v-btn icon @click="clearData">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <payments-table :payments="payments"></payments-table>
      </v-card-text>
      <v-card-actions class="pa-5">
        <v-spacer></v-spacer>
        <v-subheader class="text-subtitle-1"
          >Remaining Balance:
          <span class="font-weight-bold pl-2">
            {{ newBalance }}</span
          ></v-subheader
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { formatCurrency } from "../../utils/format-utils";
import PaymentsTable from "./payments-table";
export default {
  props: ["sessionId"],
  components: {
    PaymentsTable,
  },
  data: function () {
    return {
      dialog: false,
    };
  },
  computed: {
    payments() {
      return this.$store.state.sessionModule.payments;
    },
    billedAmount() {
      return this.$store.getters["sessionModule/getSessionById"](this.sessionId)
        .billedAmount;
    },
    newBalance() {
      return formatCurrency(
        this.billedAmount -
          this.payments.reduce(
            (a, o) => a + Number(o.paidAmount) + Number(o.adjustmentAmount),
            0
          )
      );
    },
  },
  methods: {
    async loadData() {
      this.$store.commit("uxModule/setShowLoader", true);
      await this.$store.dispatch(
        "sessionModule/getPaymentsForSession",
        this.sessionId
      );
      this.$store.commit("uxModule/setShowLoader", false);
      this.dialog = true;
    },
    clearData() {
      this.$store.dispatch("sessionModule/clearPayments");
      this.dialog = false;
    },
  },
};
</script>
