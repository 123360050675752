<template>
  <v-container>
    <v-toolbar flat>
      <v-spacer></v-spacer>
      <new-rate-button></new-rate-button>
    </v-toolbar>
    <v-row>
      <v-radio-group v-model="groupBy" row label="Group charges by">
        <v-radio label="Program" value="programName"></v-radio>
        <v-radio label="Service" value="serviceText"></v-radio>
      </v-radio-group>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        prepend-icon="mdi-magnify"
        clearable
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="serviceRates"
      :sort-by="['programName', 'serviceCode', 'effectiveDate']"
      :group-by="groupBy"
      :search="search"
      :loading="loading"
      fixed-header
      hide-default-footer
      disable-pagination
      dense
      class="elevation-1"
    >
      <template v-slot:[`group.header`]="{ group, headers, toggle, isOpen }">
        <td :colspan="headers.length">
          <v-btn @click="toggle" x-small icon :ref="group">
            <v-icon v-if="isOpen">mdi-minus</v-icon>
            <v-icon v-else>mdi-plus</v-icon>
          </v-btn>
          <span class="mx-5 font-weight-bold" style="font-size: 14px">{{
            group
          }}</span>
        </td>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-btn x-small text @click="goToRateForm(item)"
                ><v-icon small>mdi-pencil</v-icon></v-btn
              >
            </span>
          </template>
          <span> Edit Rate </span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import NewRateButton from "./new-rate-button.vue";
export default {
  components: {
    NewRateButton,
  },
  async mounted() {
    this.loading = true;
    await this.$store.dispatch("billingModule/getAllServiceRates");
    this.loading = false;
  },
  data: () => ({
    loading: false,
    headers: [
      { text: "Program", value: "programName", sortable: false },
      { text: "Service", value: "serviceText", sortable: false },
      { text: "Bilingual", value: "bilingual" },
      { text: "Language(s)", value: "formattedLanguages", sortable: false },
      { text: "Effective Date", value: "formattedEffectiveDate" },
      { text: "Effective Until", value: "formattedLastEffectiveDate" },
      { text: "Unit", value: "rateUnitType", sortable: false },
      { text: "Amount", value: "formattedAmount", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    search: "",
    groupBy: "programName",
  }),
  computed: {
    serviceRates() {
      return this.$store.state.billingModule.serviceRates;
    },
  },
  methods: {
    goToRateForm(item) {
      this.$router.push({ name: "EditRate", params: { serviceRate: item } });
    },
  },
};
</script>
