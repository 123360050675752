<template>
  <v-dialog v-model="dialog" persistent width="400px">
    <template v-slot:activator="{ on }">
      <v-btn
        color="primary"
        :dark="sessionIds.length > 0"
        :disabled="!(sessionIds.length > 0)"
        small
        outlined
        v-on="on"
        @click="dialog = true"
        >Invoice Session(s)
      </v-btn>
    </template>

    <v-card>
      <validation-observer v-slot="{ invalid }">
        <v-card-title
          >Invoice {{ sessionIds.length }} sessions on:
          <date-picker
            :value="invoiceDate"
            :field="{}"
            @fieldChange="onInvoiceDateChange"
            :validations="{ required: true }"
          ></date-picker
        ></v-card-title>
        <v-card-text>
          <span class="text-caption error--text"
            >Note: This action may overwrite existing invoices.
          </span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :disabled="loading"
            @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            outlined
            :dark="!invalid && !loading"
            :disabled="invalid || loading"
            @click="invoice"
            >Invoice
          </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["sessionIds"],
  data() {
    return {
      dialog: false,
      loading: false,
      invoiceDate: new Date(),
    };
  },
  methods: {
    onInvoiceDateChange(value) {
      this.invoiceDate = value;
    },
    async invoice() {
      this.loading = true;
      let message = await this.$store.dispatch(
        "billingModule/invoiceSessions",
        {
          sessionIds: this.sessionIds,
          invoiceDate: this.invoiceDate,
        }
      );
      this.loading = false;

      this.$store.commit("uxModule/setSnackbarMsg", message);
      this.$store.commit("uxModule/setShowSnackbar", true);
      this.dialog = false;
    },
  },
};
</script>
