<template>
  <v-card-text>
    <v-toolbar flat>
      <client-search
        :clientId="clientId"
        @setClientField="clientId = $event"
      ></client-search>
    </v-toolbar>
    <v-data-table
      v-show="Boolean(clientId) && !loading"
      v-model="selected"
      :headers="headers"
      :items="filteredInvoices"
      item-key="sessionInvoiceId"
      show-select
      :loading="loading"
      sort-by="invoiceDate"
      sort-desc
    >
      <template v-slot:[`header.data-table-select`]></template>
      <template
        v-slot:[`item.data-table-select`]="{ item, isSelected, select }"
      >
        <v-simple-checkbox
          :value="isSelected || pendingSessions.includes(item.sessionId)"
          @input="select($event)"
          :disabled="pendingSessions.includes(item.sessionId)"
        ></v-simple-checkbox>
      </template>
    </v-data-table>
  </v-card-text>
</template>
<script>
import ClientSearch from "../Shared/client-search.vue";
export default {
  props: ["pendingSessions"],
  components: { ClientSearch },
  data() {
    return {
      loading: false,
      headers: [
        { text: "Session ID", value: "sessionId" },
        { text: "Service", value: "service" },
        { text: "Date of Service", value: "formattedDos" },
        { text: "Provider Name", value: "providerName" },
        {
          text: "Total Billed",
          value: "formattedBilledAmount",
          sortable: false,
        },
        { text: "Balance", value: "formattedBalance", sortable: false },
      ],
      clientId: null,
      selected: [],
      invoices: [],
    };
  },
  computed: {
    batch() {
      return this.$store.state.paymentBatchModule.selectedBatch;
    },
    filteredInvoices() {
      return (
        this.invoices.filter((item) => item.payerId == this.batch.payerId) || []
      );
    },
  },
  methods: {
    async loadData() {
      this.loading = true;
      this.$store.commit("uxModule/setShowLoader", true);
      this.invoices = await this.$store.dispatch(
        "paymentBatchModule/getInvoicesForClient",
        {
          clientId: this.clientId,
        }
      );
      this.$store.commit("uxModule/setShowLoader", false);
      this.loading = false;
    },
  },
  watch: {
    clientId: {
      immediate: true,
      handler() {
        if (this.clientId) {
          this.loadData();
        } else {
          this.loading = false;
          this.invoices = [];
        }
      },
    },
    selected: {
      handler() {
        this.$emit("setSelected", this.selected);
      },
    },
  },
};
</script>
