<template>
  <v-container>
    <h2>Billing Rate</h2>
    <validation-observer v-slot="{ invalid }">
      <v-card-text>
        <v-row>
          <v-col cols="2">
            <v-subheader class="align-center">Program</v-subheader>
          </v-col>
          <v-col cols="3">
            <validation-provider
              :rules="{ required: true }"
              v-slot="{ errors }"
            >
              <v-autocomplete
                v-model="form.programId"
                :items="programs"
                :item-text="(item) => `${item.code} - ${item.name}`"
                item-value="programId"
                dense
                :error-messages="errors"
              ></v-autocomplete>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-subheader class="align-center">Service</v-subheader>
          </v-col>
          <v-col cols="3">
            <validation-provider
              :rules="{ required: true }"
              v-slot="{ errors }"
            >
              <v-autocomplete
                v-model="form.serviceId"
                :items="services"
                :item-text="(item) => `${item.code} - ${item.name}`"
                item-value="serviceId"
                dense
                :error-messages="errors"
              ></v-autocomplete>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-checkbox
              v-model="form.isBilingual"
              label="Bilingual"
              dense
              hide-details
            ></v-checkbox>
          </v-col>
          <v-col cols="3" v-if="form.isBilingual">
            <v-select
              v-model="form.languages"
              :items="languages"
              item-text="name"
              return-object
              label="Language(s)"
              chips
              clearable
              dense
              hide-details
              multiple
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-subheader class="align-center">Effective Date</v-subheader>
          </v-col>
          <v-col cols="3">
            <date-picker
              :value="form.effectiveDate"
              :field="{}"
              @fieldChange="onEffectiveDateChange"
              :validations="{ required: true }"
            ></date-picker>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-subheader class="align-center">Effective Until</v-subheader>
          </v-col>
          <v-col cols="3">
            <date-picker
              :value="form.lastEffectiveDate"
              :field="{}"
              @fieldChange="onLastEffectiveDateChange"
              :validations="{ minDate: form.effectiveDate }"
            ></date-picker>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-subheader class="align-center">Dollar Amount</v-subheader>
          </v-col>
          <v-col cols="3">
            <validation-provider
              :rules="validations.amount"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="form.amount"
                type="number"
                filled
                dense
                prepend-icon="mdi-currency-usd"
                :error-messages="errors"
              >
              </v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-subheader class="align-center">Unit Type</v-subheader>
          </v-col>
          <v-col cols="3">
            <validation-provider
              :rules="{ required: true }"
              v-slot="{ errors }"
            >
              <v-select
                v-model="form.rateUnitTypeId"
                :items="rateUnitTypes"
                item-text="name"
                item-value="rateUnitTypeId"
                label="Unit Type"
                dense
                :error-messages="errors"
              ></v-select>
            </validation-provider>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary"
          :dark="!invalid"
          :disabled="invalid"
          @click="submit"
        >
          {{ submitLabel }}
        </v-btn>
        <v-btn color="primary" text @click="cancel"> Cancel </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </validation-observer>
  </v-container>
</template>
<script>
import {
  required,
  minValue,
  minDate,
} from "../../plugins/vee-validate/validation";
export default {
  props: ["serviceRate"],
  data() {
    return {
      form: this.serviceRate ? this.serviceRate : { effectiveDate: new Date() },
    };
  },
  mounted() {
    if (this.serviceRate) {
      this.form.effectiveDate = this.serviceRate?.effectiveDate
        ? new Date(this.serviceRate.effectiveDate)
        : null;
      this.form.lastEffectiveDate = this.serviceRate?.lastEffectiveDate
        ? new Date(this.serviceRate.lastEffectiveDate)
        : null;
    }
  },
  computed: {
    validations() {
      return {
        amount: {
          required: true,
          minValue: 0,
        },
      };
    },
    programs() {
      return this.$store.state.optionsModule.programs;
    },
    services() {
      return this.$store.state.optionsModule.services;
    },
    languages() {
      return this.$store.state.optionsModule.languages;
    },
    rateUnitTypes() {
      return this.$store.state.optionsModule.rateUnitTypes;
    },
    submitLabel() {
      return this.serviceRate ? "Update Rate" : "Create Rate";
    },
  },
  methods: {
    onEffectiveDateChange(value) {
      this.form.effectiveDate = value;
    },
    onLastEffectiveDateChange(value) {
      this.form.lastEffectiveDate = value;
    },
    cancel() {
      this.$router.push({ name: "BillingRates" });
    },
    async submit() {
      this.$store.commit("uxModule/setShowLoader", true);
      let message;
      if (this.serviceRate) {
        message = await this.$store.dispatch(
          "billingModule/updateServiceRate",
          {
            ...this.form,
          }
        );
      } else {
        message = await this.$store.dispatch(
          "billingModule/createServiceRate",
          {
            ...this.form,
          }
        );
      }
      this.$store.commit("uxModule/setShowLoader", false);
      if (message) {
        this.$store.commit("uxModule/setSnackbarMsg", "Error saving changes: " + message);
        this.$store.commit("uxModule/setShowSnackbar", true);
      } else {
        this.$router.push({ name: "BillingRates" });
      }
    },
  },
};
</script>
