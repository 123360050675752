<template>
  <validation-provider :rules="validations" v-slot="{ errors }" slim>
    <v-row dense>
      <v-autocomplete
        v-model="clientId"
        :items="clientOptions"
        :item-text="
          (item) =>
            `${item.lastName}, ${item.firstName} - ${item.osis} (${item.formattedDob})`
        "
        item-value="clientId"
        clearable
        dense
        :loading="loading"
        :filter="onFilter"
        :error-messages="errors"
      >
      </v-autocomplete>
    </v-row>
  </validation-provider>
</template>

<script>
export default {
  props: ["validations"],
  async mounted() {
    if (this.clientOptions.length === 0) {
      this.loading = true;
      await this.$store.dispatch("optionsModule/getAllClients");
      this.loading = false;
    }
  },
  data() {
    return {
      clientId: "",
      loading: false,
    };
  },
  computed: {
    clientOptions() {
      return this.$store.state.optionsModule.clients;
    },
  },
  methods: {
    onFilter(item, queryText, itemText) {
      // remove dashes from text to search from
      var search = itemText.replace(/-/g, "");
      return (
        search.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
  },
  watch: {
    clientId() {
      this.$emit("setClientField", this.clientId);
    },
  },
};
</script>
