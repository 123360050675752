import Vue from "vue";
import VueRouter from "vue-router";
import { routes } from "./routes";
import store from "../store/index";
Vue.use(VueRouter);

let router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const checkUserAccess = async (to, from, next) => {
  if (to.name !== "Error" && !store.state.securityModule.hasAccessToApp) {
    next({
      path: "/error",
    });
    // // Uncomment to use role-based access on specific routes
    // } else if (
    //   to.meta.admin &&
    //   !store.getters["securityModule/hasUserRole"]([])
    // ) {
    //   next({
    //     path: "/error",
    //     query: { page: to.name, roles: [] },
    //   });
  } else {
    next();
  }
};

router.beforeEach(async (to, from, next) => {
  await store.dispatch("securityModule/shallowAuthenticate");
  if (store.state.securityModule.isAuthenticated) {
    checkUserAccess(to, from, next);
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    store.commit("securityModule/setRequiresAuth", true);
    await store.dispatch("securityModule/authenticate", to.path).then(() => {
      checkUserAccess(to, from, next);
    });
  } else {
    store.commit("securityModule/setRequiresAuth", false);
    next();
  }
});

export default router;
