<template>
  <div>
    <v-btn
      v-if="large"
      color="primary"
      small
      tile
      outlined
      depressed
      dark
      @click="exportPaymentBatch"
      class="mx-2"
      ><v-icon small color="primary">mdi-file-export</v-icon>
      Export To Csv
    </v-btn>
    <v-btn v-else text x-small color="primary" @click="exportPaymentBatch">
      Export
    </v-btn>
  </div>
</template>
<script>
import { downloadFile } from "../../utils/download-utils";
export default {
  props: ["large", "paymentBatchId"],
  computed: {
    batch() {
      return this.$store.getters["paymentBatchModule/getPaymentBatch"](
        this.paymentBatchId
      );
    },
    fileName() {
      return `Payment-${this.batch.checkNumber}.csv`;
    },
  },
  methods: {
    async exportPaymentBatch() {
      this.$store.commit("uxModule/setShowLoader", true);
      var response = await this.$store.dispatch(
        "paymentBatchModule/exportPaymentBatch",
        { paymentBatchId: this.paymentBatchId }
      );
      if (response) {
        await downloadFile(response, this.fileName);
      } else {
        this.$store.commit(
          "uxModule/setSnackbarMsg",
          "Oops! Something went wrong. Please try again later."
        );
        this.$store.commit("uxModule/setShowSnackbar", true);
      }
      this.$store.commit("uxModule/setShowLoader", false);
    },
  },
};
</script>
