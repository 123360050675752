import * as api from "../Services/api.js";

export const state = {};

export const mutations = {};

export const getters = {};

export const actions = {
  async getRevenueDetailsReport({}, data) {
    return await api
      .post("/financialReports/getRevenueDetailsReport", data)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return false;
      });
  },
  async getRevenueSummaryReport({}, data) {
    return await api
      .post("/financialReports/getRevenueSummaryReport", data)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return false;
      });
  },
  async getCashReceiptsReport({}, data) {
    return await api
      .post("/financialReports/getCashReceiptsReport", data)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return false;
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
