import Payer from "./Payer";
import InvoicePayment from "./InvoicePayment";
import { formatCurrency, formatDate } from "../utils/format-utils";

export default class PaymentBatch {
  paymentBatchId?: number;
  paymentDate?: Date | null;
  payerId?: number | null;
  payer?: Payer | null;
  createdAt?: Date | null;
  lastUpdatedAt?: Date | null;
  paymentMethod?: string | null;
  totalAmount?: number | null;
  checkNumber?: string | null;
  eftNumber?: string | null;
  isPosted?: boolean | null;
  postedAt?: Date | null;
  invoicePayments?: InvoicePayment[];
  totalApplied?: number | null;

  public constructor(params: PaymentBatch = {} as PaymentBatch) {
    let {
      paymentBatchId = 0,
      paymentDate = null,
      payerId = null,
      payer = null,
      createdAt = null,
      lastUpdatedAt = null,
      paymentMethod = "",
      totalAmount = 0,
      checkNumber = "",
      eftNumber = "",
      isPosted = false,
      postedAt = null,
      invoicePayments = [],
      totalApplied = 0,
    } = params;
    this.paymentBatchId = paymentBatchId;
    this.paymentDate = paymentDate;
    this.payerId = payerId;
    this.payer = payer && new Payer(payer);
    this.createdAt = createdAt;
    this.lastUpdatedAt = lastUpdatedAt;
    this.paymentMethod = paymentMethod;
    this.totalAmount = totalAmount;
    this.checkNumber = checkNumber;
    this.eftNumber = eftNumber;
    this.isPosted = isPosted;
    this.postedAt = postedAt;
    this.invoicePayments =
      invoicePayments && invoicePayments.map((i) => new InvoicePayment(i));
    this.totalApplied = totalApplied;
  }

  get formattedPaymentDate() {
    return formatDate(this.paymentDate);
  }

  get formattedCreatedAt() {
    return formatDate(this.createdAt);
  }

  get formattedPostedAt() {
    return formatDate(this.postedAt);
  }

  get formattedTotalAmount() {
    return formatCurrency(this.totalAmount);
  }

  get formattedTotalApplied() {
    return formatCurrency(this.totalApplied);
  }

  get payerName() {
    return this.payer?.name;
  }

  get status() {
    if (this.isPosted) return "Posted";
    else return "Pending";
  }
}
