<template>
  <v-data-table
    :headers="headers"
    :items="payments"
    hide-default-footer
    disable-pagination
    class="payments"
  ></v-data-table>
</template>
<script>
export default {
  props: ["payments"],
  data() {
    return {
      headers: [
        { text: "Payment Id", value: "invoicePaymentId" },
        { text: "Payment Date", value: "formattedPaymentDate" },
        { text: "Status", value: "status" },
        { text: "Posted On", value: "formattedPostedAt" },
        { text: "Payer", value: "payer" },
        { text: "Check Number", value: "checkNumber" },
        { text: "EFT Number", value: "eftNumber" },
        {
          text: "Total Billed",
          value: "formattedBilledAmount",
          sortable: false,
        },
        { text: "Payment", value: "formattedPaidAmount", sortable: false },
        {
          text: "Adjustment",
          value: "formattedAdjustmentAmount",
          sortable: false,
        },
        { text: "Adjustment Type", value: "adjustmentType", sortable: false },
      ],
    };
  },
};
</script>
<style>
.payments td {
  font-size: 12px !important;
}
</style>
