<template>
  <v-card flat>
    <v-card-title>New Invoice</v-card-title>
    <v-card-subtitle>
      <v-radio-group v-model="invoiceType" row required>
        <v-radio label="Overpayment" value="overpayment"></v-radio>
      </v-radio-group>
    </v-card-subtitle>

    <validation-observer v-slot="{ invalid }">
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="3">
            <label>Client</label>
            <client-search
              :clientId="form.clientId"
              @setClientField="form.clientId = $event"
              :validations="validations.clientId"
            ></client-search>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3">
            <validation-provider
              :rules="{ required: true }"
              v-slot="{ errors }"
            >
              <v-autocomplete
                v-model="form.programId"
                :items="programs"
                :item-text="(item) => `${item.code} - ${item.name}`"
                item-value="programId"
                label="Program"
                dense
                :error-messages="errors"
              ></v-autocomplete>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3">
            <validation-provider
              :rules="{ required: true }"
              v-slot="{ errors }"
            >
              <v-autocomplete
                v-model="form.serviceId"
                :items="services"
                :item-text="(item) => `${item.code} - ${item.name}`"
                item-value="serviceId"
                label="Service"
                dense
                :error-messages="errors"
              ></v-autocomplete>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="3">
            <date-picker
              :value="form.dateOfService"
              :field="{ label: 'Date of Service' }"
              @fieldChange="form.dateOfService = $event"
              :validations="validations.dateOfService"
            ></date-picker> </v-col
        ></v-row>
        <v-row dense>
          <v-col cols="12" sm="3">
            <date-picker
              :value="form.invoiceDate"
              :field="{ label: 'Invoice Date' }"
              @fieldChange="form.invoiceDate = $event"
              :validations="validations.invoiceDate"
            ></date-picker> </v-col
        ></v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary"
          :dark="!invalid"
          :disabled="invalid"
          @click="save"
        >
          Save
        </v-btn>
        <v-btn color="primary" text @click="cancel"> Cancel </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </validation-observer>
  </v-card>
</template>
<script>
import {
  required,
  minDate,
  maxDate,
} from "../../plugins/vee-validate/validation";
import ClientSearch from "../Shared/client-search.vue";
export default {
  components: {
    ClientSearch,
  },
  data() {
    return {
      invoiceType: "overpayment",
      form: { invoiceDate: new Date() },
    };
  },
  computed: {
    validations() {
      return {
        clientId: {
          required: true,
        },
        dateOfService: {
          required: true,
          maxDate: new Date(),
        },
        invoiceDate: {
          required: true,
        },
      };
    },
    programs() {
      return this.$store.state.optionsModule.programs;
    },
    services() {
      return this.$store.state.optionsModule.services;
    },
  },
  methods: {
    cancel() {
      this.$router.push({ name: "Home" });
    },
    async save() {
      this.$store.commit("uxModule/setShowLoader", true);
      let success;
      if (this.invoiceType === "overpayment") {
        success = await this.$store.dispatch(
          "billingModule/createOverpaymentInvoice",
          {
            ...this.form,
          }
        );
      }

      this.$store.commit("uxModule/setShowLoader", false);
      if (!success) {
        this.$store.commit("uxModule/setSnackbarMsg", "Error saving changes");
        this.$store.commit("uxModule/setShowSnackbar", true);
      } else {
        this.$store.commit("uxModule/setSnackbarMsg", "Successfully saved invoice");
        this.$store.commit("uxModule/setShowSnackbar", true);
        this.$router.push({ name: "Home" });
      }
    },
  },
};
</script>
