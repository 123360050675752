export default class Service {
  serviceId?: number;
  code?: string | null;
  name?: string | null;
  description?: string | null;
  isEvaluation?: Boolean | null;

  public constructor(params: Service = {} as Service) {
    let {
      serviceId = 0,
      code = "",
      name = "",
      description = "",
      isEvaluation = false,
    } = params;
    this.serviceId = serviceId;
    this.code = code;
    this.name = name;
    this.description = description;
    this.isEvaluation = isEvaluation;
  }
}
