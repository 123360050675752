import { library } from "@fortawesome/fontawesome-svg-core";
// Official documentation available at: https://github.com/FortAwesome/vue-fontawesome
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import
{
  faCommentDots,
  faSlash,
  faSave,
  faBars,
  faEnvelope,
  faHeart,
  faGraduationCap,
  faHome,
  faInfo,
  faList,
  faPlus,
  faMinus,
  faFolderPlus,
  faFolderOpen,
  faSearch,
  faFileAlt,
  faPen,
  faUserPlus,
  faFileMedical,
  faFile,
  faUser,
  faUsers,
  faCode,
  faCheck,
  faPlusCircle,
  faSpinner,
  faSignInAlt,
  faSignOutAlt,
  faTimes,
  faPhoneAlt,
  faArrowLeft,
  faArrowRight,
  faQuestionCircle,
  faTrashAlt,
  faClipboardList,
  faEdit,
  faCalendarAlt,
  faCalendarCheck,
  faCheckDouble,
  faArchive,
  faPauseCircle,
  faBaby,
  faChild,
  faAngleDoubleDown,
  faAngleDoubleLeft,
  faCalendarDay,
  faEraser,
  faPaperclip,
  faExpandAlt,
  faInbox,
  faPaperPlane,
  faCompressAlt,
  faReply,
  faReplyAll,
  faForward,
  faFileWord,
  faFilePdf,
  faFileExcel,
  faFileImage,
  faCloudDownloadAlt,
  faAngleRight,
  faAngleLeft,
  faSyncAlt,
  faDollarSign,
  faMoneyCheck,
  faMoneyCheckAlt,
  faFunnelDollar,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFontAwesome,
  faMicrosoft,
  faVuejs
} from "@fortawesome/free-brands-svg-icons";

// If not present, it won't be visible within the application. Considering that you
// don't want all the icons for no reason. This is a good way to avoid importing too many
// unnecessary things.
library.add(
  faCommentDots,

  faSlash,
  faSave,
  faBars,
  faEnvelope,
  faHeart,
  faGraduationCap,
  faHome,
  faInfo,
  faList,
  faFolderPlus,
  faFolderOpen,
  faSearch,
  faFileAlt,
  faMinus,
  faPen,
  faUserPlus,
  faFileMedical,
  faFile,
  faUser,
  faCode,
  faUsers,
  faSpinner,
  faSignInAlt,
  faSignOutAlt,
  faTimes,
  faPhoneAlt,
  faArrowLeft,
  faArrowRight,
  faQuestionCircle,
  faPlus,
  faPlusCircle,
  faCheck,
  faEdit,
  // Brands
  faFontAwesome,
  faTrashAlt,
  faClipboardList,
  faCalendarAlt,
  faCalendarCheck,
  faCheckDouble,
  faArchive,
  faPauseCircle,
  faBaby,
  faChild,
  faEraser,
  faPaperclip,
  faExpandAlt,
  faCompressAlt,
  faAngleDoubleDown,
  faAngleDoubleLeft,
  faCalendarDay,
  faMicrosoft,
  faInbox,
  faPaperPlane,
  faVuejs,
  faReply,
  faReplyAll,
  faForward,
  faFileWord,
  faFilePdf,
  faFileExcel,
  faFileImage,
  faCloudDownloadAlt,
  faAngleRight,
  faAngleLeft,
  faSyncAlt,
  faDollarSign,
  faMoneyCheck,
  faMoneyCheckAlt,
  faFunnelDollar,
);

export { FontAwesomeIcon };
