import * as api from "../Services/api.js";
import PaymentBatch from "../../objs/PaymentBatch";
import SessionInvoice from "../../objs/SessionInvoice";

export const state = {
  batches: [],
  selectedBatch: null,
};

export const mutations = {
  SET_BATCHES(state, payload) {
    state.batches = payload.map((item) => new PaymentBatch(item));
  },
  ADD_BATCH(state, payload) {
    state.batches.push(new PaymentBatch(payload));
  },
  UPDATE_BATCH(state, payload) {
    state.batches = state.batches.map((item) => {
      return item.paymentBatchId == payload.paymentBatchId
        ? new PaymentBatch(payload)
        : item;
    });
  },
  DELETE_BATCH(state, batchId) {
    state.batches = state.batches.filter(
      (item) => item.paymentBatchId != batchId
    );
  },
  SET_SELECTED_BATCH(state, payload) {
    state.selectedBatch = new PaymentBatch(payload);
  },
};

export const getters = {
  getPaymentBatch: (state) => (paymentBatchId) => {
    return state.batches.find((item) => item.paymentBatchId === paymentBatchId);
  },
};

export const actions = {
  async getAllBatches({ commit }, data) {
    return await api
      .get("/paymentBatch/getAllPaymentBatches", data)
      .then((response) => {
        if (response.success) {
          commit("SET_BATCHES", response.data);
        }
        return response.success;
      });
  },
  async loadBatch({ commit }, data) {
    return await api
      .getQuery("/paymentBatch/loadPaymentBatch", data)
      .then((response) => {
        if (response.success) {
          commit("SET_SELECTED_BATCH", response.data);
        }
        return response.success;
      });
  },
  async createBatch({ commit }, data) {
    return await api
      .post("/paymentBatch/createPaymentBatch", data)
      .then((response) => {
        if (response.success) {
          commit("ADD_BATCH", response.data);
          return "";
        }
        return response.message;
      });
  },
  async updateBatch({ commit }, data) {
    return await api
      .post("/paymentBatch/updatePaymentBatch", data)
      .then((response) => {
        if (response.success) {
          commit("UPDATE_BATCH", response.data);
          return "";
        }
        return response.message;
      });
  },
  async deleteBatch({ commit }, data) {
    return await api
      .postQuery("/paymentBatch/deletePaymentBatch", {
        paymentBatchId: data.paymentBatchId,
      })
      .then((response) => {
        if (response.success) {
          commit("DELETE_BATCH", data.paymentBatchId);
        }
        return response.success;
      });
  },
  async getInvoicesForClient({ commit }, data) {
    return await api
      .getQuery("/sessionInvoice/getInvoicesForClient", data)
      .then((response) => {
        if (response.success) {
          return response.data?.map((item) => new SessionInvoice(item));
        }

        return response.success;
      });
  },
  async addInvoiceToBatch({ commit }, data) {
    return await api
      .postQuery("/paymentBatch/addInvoicePayment", data)
      .then((response) => {
        if (response.success) {
          commit("UPDATE_BATCH", response.data);
          commit("SET_SELECTED_BATCH", response.data);
        }
        return response.success;
      });
  },
  async updateInvoiceInBatch({ commit }, data) {
    return await api
      .post("/paymentBatch/updateInvoicePayment", data)
      .then((response) => {
        if (response.success) {
          commit("UPDATE_BATCH", response.data);
          commit("SET_SELECTED_BATCH", response.data);
        }
        return response.success;
      });
  },
  async deleteInvoiceFromBatch({ commit }, data) {
    return await api
      .postQuery("/paymentBatch/deleteInvoicePayment", {
        invoicePaymentId: data.invoicePaymentId,
      })
      .then((response) => {
        if (response.success) {
          commit("UPDATE_BATCH", response.data);
          commit("SET_SELECTED_BATCH", response.data);
        }
        return response.success;
      });
  },
  async postBatch({ commit }, data) {
    return await api
      .postQuery("/paymentBatch/postPaymentBatch", {
        paymentBatchId: data.paymentBatchId,
      })
      .then((response) => {
        if (response.success) {
          commit("UPDATE_BATCH", response.data);
          commit("SET_SELECTED_BATCH", response.data);
        }
        return response.success;
      });
  },
  async exportPaymentBatch({}, data) {
    return await api
      .postQuery("/paymentBatch/exportPaymentBatch", data)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return false;
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
