<template>
  <v-card flat class="primary--text">
    <validation-observer v-slot="{ invalid }">
      <v-card-title>Cash Receipts</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="3">
            <date-picker
              :value="startDate"
              :field="{ label: 'Start Date' }"
              @fieldChange="startDate = $event"
              :validations="validations.startDate"
            ></date-picker>
          </v-col>
          <v-col cols="12" sm="3">
            <date-picker
              :value="endDate"
              :field="{ label: 'End Date' }"
              @fieldChange="endDate = $event"
              :validations="validations.endDate"
            ></date-picker>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary"
          :dark="!invalid"
          :disabled="invalid"
          outlined
          rounded
          @click="downloadReport"
          >Download Report</v-btn
        >
      </v-card-actions>
    </validation-observer>
  </v-card>
</template>
<script>
import { downloadFile } from "../../utils/download-utils";
import { formatDate } from "../../utils/format-utils";
export default {
  data() {
    return {
      startDate: null,
      endDate: null,
    };
  },
  computed: {
    validations() {
      return {
        startDate: {
          required: true,
        },
        endDate: {
          required: true,
          minDate: this.startDate,
        },
      };
    },
    fileName() {
      return (
        "CashReceipts - " +
        formatDate(this.startDate) +
        " through " +
        formatDate(this.endDate) +
        ".csv"
      );
    },
  },
  methods: {
    async downloadReport() {
      this.$store.commit("uxModule/setShowLoader", true);
      var response = await this.$store.dispatch(
        "reportsModule/getCashReceiptsReport",
        {
          start: this.startDate,
          end: this.endDate,
        }
      );

      if (response) {
        await downloadFile(response, this.fileName);
      } else {
        this.$store.commit(
          "uxModule/setSnackbarMsg",
          "Oops! Something went wrong. Please try again later."
        );
        this.$store.commit("uxModule/setShowSnackbar", true);
      }
      this.$store.commit("uxModule/setShowLoader", false);
    },
  },
};
</script>
