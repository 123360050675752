import Home from "../components/HomePage/home";

import SessionsHome from "../components/Sessions/sessions-home";
import SessionLookup from "../components/Sessions/session-lookup";

import BillingHome from "../components/Billing/billing-home";
import BillingRates from "../components/Billing/billing-rates";
import BillingRatesForm from "../components/Billing/billing-rates-form";
import InvoiceForm from "../components/Billing/invoice-form";

import PaymentsHome from "../components/Payments/payments-home";
import AllBatches from "../components/Payments/all-batches";
import BatchForm from "../components/Payments/batch-form";
import Batch from "../components/Payments/batch";

import ReportsHome from "../components/Reports/reports-home";
import RevenueReports from "../components/Reports/revenue-reports";
import CashReceipts from "../components/Reports/cash-receipts";

import Error from "../components/Security/error";
import Callback from "../components/Security/callback";
import store from "../store/index";

export const routes = [
  {
    name: "Home",
    path: "/",
    icon: "home",
    title: "Home",
    component: Home,
    onHomePage: false,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: "Sessions",
    path: "/",
    icon: "search",
    title: "Sessions",
    component: SessionsHome,
    onNavBar: true,
    onHomePage: true,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        name: "SessionLookup",
        path: "/sessions/session-lookup",
        icon: "search",
        title: "Session Lookup",
        component: SessionLookup,
        onNavBar: true,
        onHomePage: true,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    name: "Billing",
    path: "/",
    icon: "money-check-alt",
    title: "Billing",
    component: BillingHome,
    onNavBar: true,
    onHomePage: true,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        name: "BillingRates",
        path: "/billing/rates",
        icon: "money-check-alt",
        title: "Billing Rates",
        component: BillingRates,
        onNavBar: true,
        onHomePage: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "NewRate",
        path: "/billing/rates/new",
        icon: "money-check-alt",
        title: "New Billing Rate",
        component: BillingRatesForm,
        onNavBar: true,
        onHomePage: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "EditRate",
        path: "/billing/rates/edit",
        icon: "money-check-alt",
        title: "Edit Billing Rate",
        component: BillingRatesForm,
        props: true,
        onNavBar: false,
        onHomePage: false,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "NewInvoice",
        path: "/billing/invoices/new",
        icon: "money-check-alt",
        title: "New Invoice",
        component: InvoiceForm,
        onNavBar: true,
        onHomePage: true,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    name: "Payments",
    path: "/",
    icon: "funnel-dollar",
    title: "Payments",
    component: PaymentsHome,
    onNavBar: true,
    onHomePage: true,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        name: "AllBatches",
        path: "/payments/all-checks",
        icon: "funnel-dollar",
        title: "All Checks",
        component: AllBatches,
        onNavBar: true,
        onHomePage: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "NewBatch",
        path: "/payments/new-check",
        icon: "funnel-dollar",
        title: "New Check",
        component: BatchForm,
        onNavBar: true,
        onHomePage: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "EditBatch",
        path: "/payments/edit-check",
        title: "Edit Check",
        component: BatchForm,
        onNavBar: false,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "ProcessBatch",
        path: "/payments/process-check",
        title: "Process Check",
        component: Batch,
        onNavBar: false,
        props: true,
        meta: {
          requiresAuth: true,
        },
        beforeEnter: (to, from, next) => {
          if (!store.state.paymentBatchModule.selectedBatch)
            next({ name: "AllBatches" });
          else next();
        },
      },
      {
        name: "ReviewBatch",
        path: "/payments/review-check",
        title: "Review Check",
        component: Batch,
        onNavBar: false,
        props: { noEdit: true },
        meta: {
          requiresAuth: true,
        },
        beforeEnter: (to, from, next) => {
          if (!store.state.paymentBatchModule.selectedBatch)
            next({ name: "AllBatches" });
          else next();
        },
      },
    ],
  },
  {
    name: "Reports",
    path: "/",
    icon: "file-excel",
    title: "Reports",
    component: ReportsHome,
    onNavBar: true,
    onHomePage: true,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        name: "RevenueReports",
        path: "/reports/revenue-reports",
        icon: "file-excel",
        title: "Revenue Report",
        component: RevenueReports,
        onNavBar: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "CashReceipts",
        path: "/reports/cash-receipts",
        icon: "file-excel",
        title: "Cash Receipts",
        component: CashReceipts,
        onNavBar: true,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    name: "Error",
    path: "/error",
    title: "Error",
    component: Error,
    onNavBar: false,
  },
  {
    name: "Callback",
    path: "/callback",
    title: "Callback",
    component: Callback,
    onNavBar: false,
    onHomePage: false,
  },
];
