import { formatCurrency, formatDate, formatOsis } from "../utils/format-utils";

export default class InvoicePayment {
  invoicePaymentId?: number;
  sessionInvoiceId?: number | null;
  sessionId?: number | null;
  clientFirstName?: string | null;
  clientLastName?: string | null;
  clientOsis?: string | null;
  providerFirstName?: string | null;
  providerLastName?: string | null;
  dateOfService?: Date | null;
  serviceName?: string | null;
  invoiceDate?: Date | null;
  billedAmount?: number | null;
  balance?: number | null;
  paymentBatchId?: number | null;
  paymentDate?: Date | null;
  payer?: string | null;
  checkNumber?: string | null;
  eftNumber?: string | null;
  postedAt?: Date | null;
  paidAmount: number;
  adjustmentAmount?: number | null;
  adjustmentType?: string | null;
  isPaid?: boolean | null;

  public constructor(params: InvoicePayment = {} as InvoicePayment) {
    let {
      invoicePaymentId = 0,
      sessionInvoiceId = null,
      sessionId = null,
      clientFirstName = "",
      clientLastName = "",
      clientOsis = "",
      providerFirstName = "",
      providerLastName = "",
      dateOfService = null,
      serviceName = "",
      invoiceDate = null,
      billedAmount = 0,
      balance = 0,
      paymentBatchId = null,
      paymentDate = null,
      payer = null,
      checkNumber = "",
      eftNumber = "",
      postedAt = null,
      paidAmount = 0,
      adjustmentAmount = 0,
      adjustmentType = "",
      isPaid = false,
    } = params;
    this.invoicePaymentId = invoicePaymentId;
    this.sessionInvoiceId = sessionInvoiceId;
    this.sessionId = sessionId;
    this.clientFirstName = clientFirstName;
    this.clientLastName = clientLastName;
    this.clientOsis = clientOsis && formatOsis(clientOsis);
    this.providerFirstName = providerFirstName;
    this.providerLastName = providerLastName;
    this.dateOfService = dateOfService;
    this.serviceName = serviceName;
    this.invoiceDate = invoiceDate;
    this.billedAmount = billedAmount;
    this.balance = balance;
    this.paymentBatchId = paymentBatchId;
    this.paymentDate = paymentDate;
    this.payer = payer;
    this.checkNumber = checkNumber;
    this.eftNumber = eftNumber;
    this.postedAt = postedAt;
    this.paidAmount = paidAmount;
    this.adjustmentAmount = adjustmentAmount;
    this.adjustmentType = adjustmentType;
    this.isPaid = isPaid;
  }

  get clientName() {
    return this.clientFirstName + " " + this.clientLastName;
  }

  get providerName() {
    return this.providerFirstName + " " + this.providerLastName;
  }

  get formattedDos() {
    return formatDate(this.dateOfService);
  }

  get formattedInvoiceDate() {
    return formatDate(this.invoiceDate);
  }

  get formattedBilledAmount() {
    return formatCurrency(this.billedAmount);
  }

  get formattedBalance() {
    return formatCurrency(this.balance);
  }

  get formattedPaymentDate() {
    return formatDate(this.paymentDate);
  }

  get formattedPaidAmount() {
    return formatCurrency(this.paidAmount);
  }

  get formattedAdjustmentAmount() {
    return formatCurrency(this.adjustmentAmount || 0);
  }

  get status() {
    return this.isPaid ? "Posted" : "Pending";
  }

  get formattedPostedAt() {
    return this.postedAt ? formatDate(this.postedAt) : "";
  }

  get formattedNewBalance() {
    let origBalance = this.balance || 0;
    let paidAmount = this.paidAmount || 0;
    let adjustAmount = this.adjustmentAmount || 0;
    return formatCurrency(origBalance - (paidAmount + adjustAmount));
  }
}
