<template>
  <v-dialog v-model="dialog" persistent width="60vw" height="60vw">
    <template v-slot:activator="{ on }">
      <v-btn
        color="primary"
        small
        tile
        outlined
        depressed
        :dark="!noEdit"
        :disabled="noEdit"
        v-on="on"
        @click="dialog = true"
        ><v-icon small color="primary">mdi-plus</v-icon>
        Add Invoice(s)
      </v-btn>
    </template>

    <v-card>
      <v-card-title>Select invoices to apply to this payment</v-card-title>
      <invoice-lookup
        v-if="dialog"
        :pendingSessions="pendingSessions"
        @setSelected="setSelected"
      ></invoice-lookup>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text :disabled="loading" @click="dialog = false">
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          outlined
          :dark="!invalid && !loading"
          :disabled="invalid || loading"
          @click="apply"
          >Apply
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import InvoiceLookup from "./invoice-lookup.vue";
export default {
  props: ["noEdit"],
  components: { InvoiceLookup },
  data() {
    return {
      dialog: false,
      loading: false,
      selected: [],
    };
  },
  computed: {
    invalid() {
      return this.selected.length === 0;
    },
    batchId() {
      return this.$store.state.paymentBatchModule.selectedBatch?.paymentBatchId;
    },
    pendingSessions() {
      return this.$store.state.paymentBatchModule.selectedBatch?.invoicePayments.map(
        (item) => item.sessionId
      );
    },
  },
  methods: {
    setSelected(value) {
      this.selected = value;
    },
    async apply() {
      this.loading = true;
      for await (const invoice of this.selected) {
        await this.$store.dispatch("paymentBatchModule/addInvoiceToBatch", {
          paymentBatchId: this.batchId,
          sessionInvoiceId: invoice.sessionInvoiceId,
        });
      }
      this.loading = false;
      this.dialog = false;
    },
  },
};
</script>
