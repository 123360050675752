<template>
  <div>
    <v-row>
      <v-breadcrumbs :items="breadcrumbs" large>
        <template v-slot:divider>
          <v-icon>mdi-menu-right</v-icon>
        </template>
      </v-breadcrumbs>
      <v-toolbar flat>
        <v-spacer></v-spacer>
        Total Applied:
        <span
          class="mx-3 text-body-1 font-weight-bold text-decoration-underline"
        >
          {{ batch.formattedTotalApplied }}</span
        >
        <span v-if="!canPostBatch" class="text-caption error--text">
          * Total applied does not equal payment amount.</span
        >
        <v-btn
          text
          color="primary"
          :dark="!noEdit && canPostBatch"
          :disabled="noEdit || !canPostBatch"
          @click="post"
          >Post Payment</v-btn
        >
      </v-toolbar>
    </v-row>
    <v-toolbar flat>
      <v-spacer></v-spacer>
      <export-batch-button
        :large="true"
        :paymentBatchId="batch.paymentBatchId"
      ></export-batch-button>

      <invoices-modal :noEdit="noEdit"></invoices-modal>
    </v-toolbar>
    <v-data-table
      :headers="_headers"
      :items="invoices"
      fixed-header
      height="70vh"
    >
      <template v-slot:[`item.paidAmount`]="{ item }">
        <v-edit-dialog v-if="!noEdit" large @save="updatePayment(item)">
          {{ item.formattedPaidAmount }}
          <template v-slot:input>
            <validation-provider
              :rules="validations.paidAmount"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="item.newPaidAmount"
                @change="onPaidAmountChange(item, $event)"
                type="number"
                dense
                prepend-icon="mdi-currency-usd"
                single-line
                autofocus
                :error-messages="errors"
              >
              </v-text-field>
            </validation-provider>
          </template>
        </v-edit-dialog>
        <span v-else>{{ item.formattedPaidAmount }}</span>
      </template>

      <template v-slot:[`item.adjustmentAmount`]="{ item }">
        <v-edit-dialog v-if="!noEdit" large @save="updatePayment(item)">
          {{ item.formattedAdjustmentAmount }}
          <template v-slot:input>
            <validation-provider
              :rules="{ required: true }"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="item.newAdjustmentAmount"
                type="number"
                dense
                prepend-icon="mdi-currency-usd"
                single-line
                autofocus
                :error-messages="errors"
              >
              </v-text-field>
            </validation-provider>
          </template>
        </v-edit-dialog>
        <span v-else>{{ item.formattedAdjustmentAmount }}</span>
      </template>

      <template v-slot:[`item.adjustmentType`]="{ item }">
        <v-edit-dialog v-if="!noEdit" large @save="updatePayment(item)">
          {{ item.adjustmentType || "N/A" }}
          <template v-slot:input>
            <v-select
              v-model="item.newAdjustmentType"
              :items="adjustmentTypes"
              dense
              autofocus
              clearable
            ></v-select>
          </template>
        </v-edit-dialog>
        <span v-else> {{ item.adjustmentType || "N/A" }}</span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="primary" v-on="on" icon @click="removeInvoice(item)">
              <v-icon small>mdi-minus-circle-outline</v-icon>
            </v-btn>
          </template>
          <span>Remove Invoice</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import InvoicesModal from "./invoices-modal.vue";
import ExportBatchButton from "./export-batch-button.vue";
import { required, minValue } from "../../plugins/vee-validate/validation";
export default {
  props: ["noEdit"],
  components: { InvoicesModal, ExportBatchButton },
  data() {
    return {
      headers: [
        { text: "Client", value: "clientName" },
        { text: "OSIS", value: "clientOsis" },
        { text: "Date of Service", value: "formattedDos" },
        { text: "Service", value: "serviceName" },
        { text: "Invoice Date", value: "formattedInvoiceDate" },
        {
          text: "Total Billed",
          value: "formattedBilledAmount",
          sortable: false,
        },
        {
          text: "Balance",
          value: "formattedBalance",
          sortable: false,
          hide: this.noEdit,
        },
        {
          text: "Payment",
          value: "paidAmount",
          sortable: false,
          cellClass: "font-weight-medium",
        },
        {
          text: "Adjustment",
          value: "adjustmentAmount",
          sortable: false,
          cellClass: "font-weight-medium",
        },
        {
          text: "Adjustment Type",
          value: "adjustmentType",
          sortable: false,
          cellClass: "font-weight-medium",
        },
        {
          text: "New Balance",
          value: "formattedNewBalance",
          sortable: false,
          hide: this.noEdit,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          hide: this.noEdit,
        },
      ],
    };
  },
  mounted() {
    // set default state for edit-dialogs
    this.reset();
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: "Payment",
          to: "all-checks",
        },
        {
          text: `${this.batch.formattedPaymentDate}`,
        },
        {
          text: `${this.batch.checkNumber}`,
        },
        {
          text: `${this.batch.formattedTotalAmount}`,
        },
      ];
    },
    _headers() {
      return this.headers.filter((x) => !x.hide);
    },
    batch() {
      return this.$store.state.paymentBatchModule.selectedBatch;
    },
    invoices() {
      return this.batch?.invoicePayments;
    },
    canPostBatch() {
      return this.batch?.totalAmount == this.batch?.totalApplied;
    },
    validations() {
      return {
        paidAmount: {
          required: true,
          minValue: 0,
        },
      };
    },
    adjustmentTypes() {
      return this.$store.state.optionsModule.adjustmentTypes;
    },
  },
  methods: {
    reset() {
      this.invoices.forEach((item, index) => {
        this.invoices[index].newPaidAmount = item.paidAmount;
        this.invoices[index].newAdjustmentAmount = item.adjustmentAmount;
        this.invoices[index].newAdjustmentType = item.adjustmentType;
      });
    },
    onPaidAmountChange(item, $event) {
      // automatically set adjustment
      const index = this.invoices.findIndex(
        (t) => t.invoicePaymentId == item.invoicePaymentId
      );
      this.invoices[index].newAdjustmentAmount = item.balance - $event;
    },
    async updatePayment(item) {
      this.$store.commit("uxModule/setShowLoader", true);
      let success = await this.$store.dispatch(
        "paymentBatchModule/updateInvoiceInBatch",
        {
          invoicePaymentId: item.invoicePaymentId,
          paymentBatchId: item.paymentBatchId,
          sessionInvoiceId: item.sessionInvoiceId,
          ...(item.newPaidAmount != null
            ? { paidAmount: item.newPaidAmount }
            : { paidAmount: item.paidAmount }),
          ...(item.newAdjustmentAmount != null
            ? { adjustmentAmount: item.newAdjustmentAmount }
            : { adjustmentAmount: item.adjustmentAmount }),
          adjustmentType: item.newAdjustmentType,
        }
      );
      this.$store.commit("uxModule/setShowLoader", false);
      if (!success) {
        this.$store.commit(
          "uxModule/setSnackbarMsg",
          "Failed to update payment"
        );
        this.$store.commit("uxModule/setShowSnackbar", true);
      }
      this.reset();
    },
    async removeInvoice(item) {
      this.$store.commit("uxModule/setShowLoader", true);
      await this.$store.dispatch("paymentBatchModule/deleteInvoiceFromBatch", {
        invoicePaymentId: item.invoicePaymentId,
      });
      this.$store.commit("uxModule/setShowLoader", false);
    },
    async post() {
      this.$store.commit("uxModule/setShowLoader", true);
      let success = await this.$store.dispatch("paymentBatchModule/postBatch", {
        paymentBatchId: this.batch.paymentBatchId,
      });
      this.$store.commit("uxModule/setShowLoader", false);
      if (!success) {
        this.$store.commit("uxModule/setSnackbarMsg", "Failed to post batch");
        this.$store.commit("uxModule/setShowSnackbar", true);
      } else {
        this.$router.push({ name: "AllBatches" });
      }
    },
  },
};
</script>
