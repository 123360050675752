import * as api from "../Services/api.js";
import SessionDetails from "../../objs/SessionDetails";
import InvoicePayment from "../../objs/InvoicePayment";

export const state = {
  sessions: [],
  payments: [],
};

export const mutations = {
  SET_SESSIONS(state, payload) {
    state.sessions = payload.map((item) => new SessionDetails(item));
  },
  SET_PAYMENTS(state, payload) {
    state.payments = payload.map((item) => new InvoicePayment(item));
  },
  updateSessionInvoiceDetails(state, payload) {
    state.sessions = state.sessions.map((item) => {
      if (item.sessionId === payload.sessionId) {
        item.invoiceDate = payload.invoiceDate;
        item.billedAmount = payload.calculatedAmount;
        item.balance = payload.balance;
      }
      return item;
    });
  },
};

export const getters = {
  getSessionById: (state) => (sessionId) => {
    return state.sessions.find((item) => item.sessionId === sessionId);
  },
};

export const actions = {
  async findByClientAndDate({ commit }, data) {
    return await api
      .getQuery("/sessionDetails/findByClientAndDate", data)
      .then((response) => {
        if (response.success) {
          commit("SET_SESSIONS", response.data);
        }
        return response.success;
      });
  },
  async findByClient({ commit }, data) {
    return await api
      .getQuery("/sessionDetails/findByClient", data)
      .then((response) => {
        if (response.success) {
          commit("SET_SESSIONS", response.data);
        }
        return response.success;
      });
  },
  async findByDateOfService({ commit }, data) {
    return await api
      .getQuery("/sessionDetails/findByDateOfService", data)
      .then((response) => {
        if (response.success) {
          commit("SET_SESSIONS", response.data);
        }
        return response.success;
      });
  },
  async findByProvider({ commit }, data) {
    return await api
      .getQuery("/sessionDetails/findByProvider", data)
      .then((response) => {
        if (response.success) {
          commit("SET_SESSIONS", response.data);
        }
        return response.success;
      });
  },
  async findBySessionId({ commit }, data) {
    return await api
      .getQuery("/sessionDetails/findBySessionId", data)
      .then((response) => {
        if (response.success) {
          commit("SET_SESSIONS", response.data);
        }
        return response.success;
      });
  },
  async getPaymentsForSession({ commit }, data) {
    return await api
      .getQuery("/paymentBatch/getPaymentsForSession", { sessionId: data })
      .then((response) => {
        if (response.success) {
          commit("SET_PAYMENTS", response.data);
        }
        return response.success;
      });
  },
  async clearPayments({ commit }) {
    commit("SET_PAYMENTS", []);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
