<template>
  <validation-observer v-slot="{ invalid }">
    <v-row>
      <v-radio-group v-model="query" label="Search by" row>
        <v-radio
          v-for="query in sessionQueries"
          :key="query"
          :label="query"
          :value="query"
        ></v-radio>
      </v-radio-group>
    </v-row>

    <v-row v-if="showClientField">
      <v-col cols="4">
        <client-search
          :clientId="clientId"
          @setClientField="clientId = $event"
        ></client-search>
      </v-col>
    </v-row>
    <v-row v-if="showDateField">
      <v-col cols="4">
        <date-picker
          :value="date"
          :field="{ label: 'Date of Service' }"
          @fieldChange="onDateChange"
          :validations="{ required: true }"
        ></date-picker>
      </v-col>
    </v-row>
    <v-row v-if="showProviderField">
      <v-col cols="4">
        <validation-provider :rules="{ required: true }">
          <v-autocomplete
            label="Provider"
            v-model="providerId"
            :items="providerOptions"
            :item-text="
              (item) =>
                `${item.firstName} ${item.lastName} (${item.legacyProviderId})`
            "
            item-value="providerId"
            clearable
            hide-details
            :loading="loadingProviders"
          ></v-autocomplete>
        </validation-provider>
      </v-col>
    </v-row>
    <v-row v-if="showSessionField">
      <v-col cols="3">
        <validation-provider :rules="{ required: true }">
          <v-text-field
            label="Session ID"
            v-model="sessionId"
            hide-details
          ></v-text-field>
        </validation-provider>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2">
        <v-btn
          @click="lookupSession"
          color="primary"
          :dark="!loading && !invalid"
          rounded
          small
          :loading="loading"
          :disabled="loading || invalid"
        >
          Lookup Sessions
        </v-btn>
      </v-col>
    </v-row>
  </validation-observer>
</template>

<script>
import { SessionQueries } from "../../objs/SessionQueries";
import ClientSearch from "../Shared/client-search.vue";
export default {
  components: {
    ClientSearch,
  },
  async mounted() {
    if (this.providerOptions.length === 0) {
      this.loadingProviders = true;
      await this.$store.dispatch("optionsModule/getAllProviders");
      this.loadingProviders = false;
    }
  },
  data() {
    return {
      sessionQueries: Object.values(SessionQueries),
      query: SessionQueries.CLIENT_AND_DATE,
      sessionId: "",
      clientId: "",
      date: "",
      providerId: "",
      loadingProviders: false,
      loading: false,
    };
  },
  computed: {
    providerOptions() {
      return this.$store.state.optionsModule.providers;
    },
    showSessionField() {
      return SessionQueries.SESSION_ID === this.query;
    },
    showClientField() {
      return [SessionQueries.CLIENT_AND_DATE, SessionQueries.CLIENT].includes(
        this.query
      );
    },
    showDateField() {
      return [
        SessionQueries.CLIENT_AND_DATE,
        SessionQueries.DATE_OF_SERVICE,
      ].includes(this.query);
    },
    showProviderField() {
      return [SessionQueries.PROVIDER].includes(this.query);
    },
  },
  methods: {
    onDateChange(event) {
      this.date = event;
    },
    async lookupSession() {
      this.loading = true;

      switch (this.query) {
        case SessionQueries.CLIENT_AND_DATE:
          await this.$store.dispatch("sessionModule/findByClientAndDate", {
            clientId: this.clientId,
            dateOfService: this.dateOfService,
          });
          break;
        case SessionQueries.CLIENT:
          await this.$store.dispatch("sessionModule/findByClient", {
            clientId: this.clientId,
          });
          break;
        case SessionQueries.DATE_OF_SERVICE:
          await this.$store.dispatch("sessionModule/findByDateOfService", {
            dateOfService: this.date,
          });
          break;
        case SessionQueries.PROVIDER:
          await this.$store.dispatch("sessionModule/findByProvider", {
            providerId: this.providerId,
          });
          break;
        case SessionQueries.SESSION_ID:
          await this.$store.dispatch("sessionModule/findBySessionId", {
            sessionId: this.sessionId,
          });
          break;
      }

      this.loading = false;

      if (this.$store.state.sessionModule.sessions.length > 0) {
        this.$emit("sessionsFound");
      } else {
        this.$store.commit("uxModule/setSnackbarMsg", "No sessions found.");
        this.$store.commit("uxModule/setShowSnackbar", true);
      }
    },
  },
};
</script>
