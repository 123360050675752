<template>
  <div>
    <v-row>
      <label class="pt-2 font-weight-bold text-subtitle-2" color="primary"
        >Search for a session...</label
      >
      <v-btn @click="toggleExpand" icon
        ><v-icon>{{ iconText }}</v-icon></v-btn
      >
    </v-row>
    <session-lookup-fields
      v-if="expanded"
      @sessionsFound="expanded = false"
    ></session-lookup-fields>
    <v-divider class="my-10"></v-divider>
    <session-details-table
      v-if="sessions.length > 0"
      :sessions="sessions"
    ></session-details-table>
  </div>
</template>

<script>
import SessionLookupFields from "./session-lookup-fields.vue";
import SessionDetailsTable from "./session-details-table.vue";
export default {
  components: {
    SessionLookupFields,
    SessionDetailsTable,
  },
  data() {
    return {
      expanded: true,
      loading: false,
    };
  },
  computed: {
    iconText() {
      return this.expanded
        ? "mdi-unfold-less-horizontal"
        : "mdi-unfold-more-horizontal";
    },
    sessions() {
      return this.$store.state.sessionModule.sessions;
    },
  },
  methods: {
    toggleExpand() {
      this.expanded = !this.expanded;
    },
  },
};
</script>
