import {
  formatCurrency,
  formatDate,
  formatOsis,
  formatTime,
} from "../utils/format-utils";

export default class SessionDetails {
  sessionId?: number;
  providerId?: number | null;
  providerName?: string | null;
  clientId?: number | null;
  clientName?: string | null;
  clientOsis?: string | null;
  clientDateOfBirth?: Date | null;
  district?: number | null;
  dateOfService?: Date | null;
  startTime?: Date | string | null;
  durationMinutes?: number | null;
  programId?: number | null;
  programName?: string | null;
  serviceId?: number | null;
  serviceName?: string | null;
  groupSize?: number | null;
  isBilingual?: boolean | null;
  language?: string | null;
  location?: string | null;
  invoiceDate?: Date | null;
  billedAmount?: number | null;
  balance?: number | null;
  public constructor(params: SessionDetails = {} as SessionDetails) {
    let {
      sessionId = 0,
      providerId = null,
      providerName = "",
      clientId = null,
      clientName = "",
      clientOsis = "",
      clientDateOfBirth = null,
      district = null,
      dateOfService = null,
      startTime = null,
      durationMinutes = 0,
      programId = null,
      programName = "",
      serviceId = null,
      serviceName = "",
      groupSize = 0,
      isBilingual = false,
      language = "",
      location = "",
      invoiceDate = null,
      billedAmount = 0,
      balance = 0,
    } = params;
    this.sessionId = sessionId;
    this.providerId = providerId;
    this.providerName = providerName;
    this.clientId = clientId;
    this.clientName = clientName;
    this.clientOsis = clientOsis && formatOsis(clientOsis);
    this.clientDateOfBirth = clientDateOfBirth;
    this.district = district;
    this.dateOfService = dateOfService;
    this.startTime = formatTime(startTime);
    this.durationMinutes = durationMinutes;
    this.programId = programId;
    this.programName = programName;
    this.serviceId = serviceId;
    this.serviceName = serviceName;
    this.groupSize = groupSize;
    this.isBilingual = isBilingual;
    this.language = language;
    this.location = location;
    this.invoiceDate = invoiceDate;
    this.billedAmount = billedAmount;
    this.balance = balance;
  }

  get formattedClientDob() {
    return formatDate(this.clientDateOfBirth);
  }

  get formattedDos() {
    return formatDate(this.dateOfService);
  }

  get formattedDuration() {
    return this.durationMinutes + " minutes";
  }

  get bilingual() {
    return this.isBilingual ? "Yes" : "No";
  }

  get formattedInvoiceDate() {
    return this.invoiceDate ? formatDate(this.invoiceDate) : "N/A";
  }

  get formattedBilledAmount() {
    return this.invoiceDate ? formatCurrency(this.billedAmount) : "N/A";
  }

  get formattedBalance() {
    return this.invoiceDate ? formatCurrency(this.balance) : "N/A";
  }
}
