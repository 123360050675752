<template>
  <validation-provider :rules="validations" v-slot="{ errors }">
    <v-textarea
      :value="value"
      :label="field.label"
      :disabled="readonly"
      @change="onChange"
      auto-grow
      rows="2"
      :prepend-icon="field.icon"
      outlined
      :error-messages="errors"
      color="#005c7b"
    ></v-textarea>
  </validation-provider>
</template>

<script>
export default {
  props: ["field", "value", "readonly", "validations"],
  methods: {
    onChange: function (event) {
      this.$emit("fieldChange", event);
    },
  },
};
</script>
