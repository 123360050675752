<template>
  <v-container class="mt-10" row fluid>
    <!-- <tile-item
      v-for="(resource, index) in resources"
      :key="index"
      :resource="resource"
      class="mr-10"
    ></tile-item> -->
  </v-container>
</template>

<script>
import { routes } from "../../router/routes";
import TileItem from "./tile-item.vue";
export default {
  components: {
    TileItem,
  },
  data() {
    return {
      routes,
    };
  },
  computed: {
    resources: function () {
      const adminRoles = ["Admin"];
      return this.routes.filter(
        (r) =>
          r.onHomePage == true &&
          (!r.meta.admin ||
            this.$store.getters["securityModule/hasUserRole"](adminRoles))
      );
    },
  },
};
</script>
