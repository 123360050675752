import ServiceRate from "./ServiceRate";
import { formatCurrency, formatDate, formatOsis } from "../utils/format-utils";
import Payer from "./Payer";

export default class SessionInvoice {
  sessionInvoiceId?: number;
  sessionId?: number | null;
  dateOfService?: Date | null;
  startTime?: Date | null;
  endTime?: Date | null;
  durationMinutes?: number | null;
  groupSize?: number | null;
  isBilingual?: boolean | null;
  language?: string | null;
  location?: string | null;
  programId?: number | null;
  program?: string | null;
  serviceId?: number | null;
  service?: string | null;
  clientId?: number | null;
  clientFirstName?: string | null;
  clientLastName?: string | null;
  clientOsis?: string | null;
  providerId?: number | null;
  providerFirstName?: string | null;
  providerLastName?: string | null;
  invoiceDate?: Date | null;
  lastProcessedAt?: Date | null;
  payerId?: number | null;
  payer?: Payer | null;
  serviceRateId?: number | null;
  serviceRate?: ServiceRate | null;
  calculatedAmount?: number | null;
  balance?: number | null;
  public constructor(params: SessionInvoice = {} as SessionInvoice) {
    let {
      sessionInvoiceId = 0,
      sessionId = null,
      dateOfService = null,
      startTime = null,
      endTime = null,
      durationMinutes = null,
      groupSize = null,
      isBilingual = false,
      language = "",
      location = "",
      programId = null,
      program = "",
      serviceId = null,
      service = "",
      clientId = null,
      clientFirstName = "",
      clientLastName = "",
      clientOsis = "",
      providerId = null,
      providerFirstName = "",
      providerLastName = "",
      invoiceDate = null,
      lastProcessedAt = null,
      payerId = null,
      payer = null,
      serviceRateId = null,
      serviceRate = null,
      calculatedAmount = 0,
      balance = 0,
    } = params;
    this.sessionInvoiceId = sessionInvoiceId;
    this.sessionId = sessionId;
    this.dateOfService = dateOfService;
    this.startTime = startTime;
    this.endTime = endTime;
    this.durationMinutes = durationMinutes;
    this.groupSize = groupSize;
    this.isBilingual = isBilingual;
    this.language = language;
    this.location = location;
    this.programId = programId;
    this.program = program;
    this.serviceId = serviceId;
    this.service = service;
    this.clientId = clientId;
    this.clientFirstName = clientFirstName;
    this.clientLastName = clientLastName;
    this.clientOsis = clientOsis && formatOsis(clientOsis);
    this.providerId = providerId;
    this.providerFirstName = providerFirstName;
    this.providerLastName = providerLastName;
    this.invoiceDate = invoiceDate;
    this.lastProcessedAt = lastProcessedAt;
    this.payerId = payerId;
    this.payer = payer && new Payer(payer);
    this.serviceRateId = serviceRateId;
    this.serviceRate = serviceRate && new ServiceRate();
    this.calculatedAmount = calculatedAmount;
    this.balance = balance;
  }

  get providerName() {
    return this.providerFirstName + " " + this.providerLastName;
  }

  get formattedDos() {
    return formatDate(this.dateOfService);
  }

  get formattedBilledAmount() {
    return formatCurrency(this.calculatedAmount);
  }

  get formattedBalance() {
    return formatCurrency(this.balance);
  }
}
