<template>
  <div>
    <v-toolbar flat>
      <v-col cols="4">
        <v-text-field
          v-model="search"
          prepend-icon="mdi-magnify"
          clearable
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-select
          v-model="status"
          :items="statuses"
          label="Status"
          dense
          hide-details
          clearable
        ></v-select>
      </v-col>
      <v-spacer></v-spacer>
      <new-batch-button></new-batch-button>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="filteredBatches"
      :search="search"
      :loading="loading"
      sort-by="createdAt"
      sort-desc
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-row>
          <v-btn
            text
            x-small
            color="primary"
            @click="editBatch(item)"
            v-if="!Boolean(item.postedAt)"
          >
            Edit
          </v-btn>
          <v-btn
            text
            x-small
            color="primary"
            @click="goToBatch(item, 'ProcessBatch')"
            v-if="!Boolean(item.postedAt)"
          >
            Process
          </v-btn>
          <v-btn
            text
            x-small
            color="primary"
            @click="goToBatch(item, 'ReviewBatch')"
            v-if="Boolean(item.postedAt)"
          >
            Review
          </v-btn>
          <export-batch-button
            v-if="Boolean(item.postedAt)"
            :large="false"
            :paymentBatchId="item.paymentBatchId"
          ></export-batch-button>
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import NewBatchButton from "./new-batch-button.vue";
import ExportBatchButton from "./export-batch-button.vue";
export default {
  components: {
    NewBatchButton,
    ExportBatchButton,
  },
  async mounted() {
    this.loading = true;
    await this.$store.dispatch("paymentBatchModule/getAllBatches");
    this.loading = false;
  },
  data() {
    return {
      headers: [
        { text: "Batch ID", value: "paymentBatchId" },
        { text: "Payment Date", value: "formattedPaymentDate" },
        { text: "Payer", value: "payerName" },
        { text: "Payment Method", value: "paymentMethod", sortable: false },
        { text: "Check Number", value: "checkNumber", sortable: false },
        { text: "EFT Number", value: "eftNumber", sortable: false },
        {
          text: "Total Amount",
          value: "formattedTotalAmount",
          sortable: false,
        },
        { text: "Created On", value: "formattedCreatedAt" },
        { text: "Posted On", value: "formattedPostedAt" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      loading: false,
      search: "",
      status: "",
    };
  },
  computed: {
    batches() {
      return this.$store.state.paymentBatchModule.batches || [];
    },
    filteredBatches() {
      if (this.status) {
        return this.batches.filter((item) => item.status == this.status);
      }
      return this.batches;
    },
    statuses() {
      return ["Pending", "Posted"];
    },
  },
  methods: {
    editBatch(item) {
      this.$router.push({
        name: "EditBatch",
        params: {
          batch: item,
        },
      });
    },
    async goToBatch(item, routeName) {
      this.$store.commit("uxModule/setShowLoader", true);
      await this.$store.dispatch("paymentBatchModule/loadBatch", {
        paymentBatchId: item.paymentBatchId,
      });
      this.$store.commit("uxModule/setShowLoader", false);
      this.$router.push({ name: routeName });
    },
  },
};
</script>
