<template>
  <v-btn
    small
    tile
    outlined
    depressed
    color="primary"
    dark
    @click="goToNewRate"
    ><v-icon small color="primary">mdi-plus</v-icon>New Rate</v-btn
  >
</template>
<script>
export default {
  methods: {
    goToNewRate() {
      this.$router.push({ name: "NewRate" });
    },
  },
};
</script>
