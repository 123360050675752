export default class Payer {
  payerId?: number | null;
  legacyRespPartyId?: string | null;
  title?: string | null;
  name?: string | null;
  streetAddress?: string | null;
  streetAddressLine2?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
  contact?: string | null;

  public constructor(params: Payer = {} as Payer) {
    let {
      payerId = 0,
      legacyRespPartyId = "",
      name = "",
      streetAddress = "",
      streetAddressLine2 = "",
      city = "",
      state = "",
      zipCode = "",
      contact = "",
    } = params;
    this.payerId = payerId;
    this.legacyRespPartyId = legacyRespPartyId;
    this.name = name;
    this.streetAddress = streetAddress;
    this.streetAddressLine2 = streetAddressLine2;
    this.city = city;
    this.state = state;
    this.zipCode = zipCode;
    this.contact = contact;
  }
}
