<template>
  <v-card flat class="primary--text">
    <validation-observer v-slot="{ invalid }">
      <v-card-title>Revenue Report</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="3">
            <date-picker
              :value="startDate"
              :field="{ label: 'Start Date' }"
              @fieldChange="startDate = $event"
              :validations="validations.startDate"
            ></date-picker>
          </v-col>
          <v-col cols="12" sm="3">
            <date-picker
              :value="endDate"
              :field="{ label: 'End Date' }"
              @fieldChange="endDate = $event"
              :validations="validations.endDate"
            ></date-picker>
          </v-col>
        </v-row>
        <v-divider class="my-5"></v-divider>
        <div class="text-subtitle-1 font-weight-bold">Organization</div>
        <v-row>
          <v-col cols="12" sm="3">
            <v-radio-group v-model="reportType" mandatory>
              <v-radio
                v-for="(item, index) in reportTypes"
                :key="index"
                :label="item"
                :value="item"
              >
              </v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" sm="4" v-if="showGroupBy">
            <div class="text-subtitle-2">Group By:</div>
            <v-chip-group v-model="groupBy" column multiple mandatory>
              <v-chip
                v-for="item in groupByOptions"
                :key="item"
                :value="item"
                filter
                filter-icon="mdi-check"
                >{{ item }}</v-chip
              >
            </v-chip-group>
          </v-col>
        </v-row>
        <v-divider class="my-5"></v-divider>
        <div class="text-subtitle-1 font-weight-bold">Filters</div>
        <v-row>
          <v-col cols="12" sm="3">
            <v-autocomplete
              v-model="selectedPrograms"
              :items="programOptions"
              :item-text="(item) => `${item.code} - ${item.name}`"
              item-value="programId"
              label="Programs"
              multiple
              small-chips
              clearable
              deletable-chips
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3">
            <v-autocomplete
              v-model="selectedServices"
              :items="serviceOptions"
              :item-text="(item) => `${item.code} - ${item.name}`"
              item-value="serviceId"
              label="Services"
              multiple
              small-chips
              clearable
              deletable-chips
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3">
            <v-autocomplete
              v-model="selectedClients"
              :items="clientOptions"
              :item-text="
                (item) => `${item.firstName} ${item.lastName} - ${item.osis}`
              "
              item-value="clientId"
              label="Clients"
              multiple
              small-chips
              clearable
              deletable-chips
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3">
            <v-autocomplete
              v-model="selectedProviders"
              :items="providerOptions"
              :item-text="
                (item) =>
                  `${item.firstName} ${item.lastName} - ${item.legacyProviderId}`
              "
              item-value="providerId"
              label="Providers"
              multiple
              small-chips
              clearable
              deletable-chips
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3">
            <v-select
              v-model="selectedLocations"
              :items="locationOptions"
              item-text="name"
              item-value="sessionLocationId"
              label="Locations"
              multiple
              small-chips
              clearable
              deletable-chips
            ></v-select>
          </v-col>
          <v-col cols="12" sm="3">
            <v-select
              v-model="selectedDistricts"
              :items="districtOptions"
              label="Districts"
              multiple
              small-chips
              clearable
              deletable-chips
            ></v-select>
          </v-col>
        </v-row>
        <v-checkbox
          v-model="showZeroBalances"
          label="Show Zero Balances"
        ></v-checkbox>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary"
          :dark="!invalid"
          :disabled="invalid"
          outlined
          rounded
          @click="downloadReport"
          >Download Report</v-btn
        >
      </v-card-actions>
    </validation-observer>
  </v-card>
</template>
<script>
import { RevenueReportTypes } from "../../objs/RevenueReportTypes.js";
import { required, minDate } from "../../plugins/vee-validate/validation";
import { downloadFile } from "../../utils/download-utils";
import { formatDate } from "../../utils/format-utils";
export default {
  async mounted() {
    this.loading = true;
    if (this.providerOptions.length === 0) {
      await this.$store.dispatch("optionsModule/getAllProviders");
    }
    if (this.clientOptions.length === 0) {
      await this.$store.dispatch("optionsModule/getAllClients");
    }
    this.loading = false;
    // set initial values
    this.groupBy = this.groupByOptions.Find();
  },
  data() {
    return {
      loading: false,
      reportTypes: Object.values(RevenueReportTypes),
      startDate: null,
      endDate: null,
      reportType: "",
      groupBy: [],
      selectedPrograms: [],
      selectedServices: [],
      selectedClients: [],
      selectedProviders: [],
      selectedLocations: [],
      selectedDistricts: [],
      showZeroBalances: false,
    };
  },
  computed: {
    validations() {
      return {
        startDate: {
          required: true,
        },
        endDate: {
          required: true,
          minDate: this.startDate,
        },
      };
    },
    programOptions() {
      return this.$store.state.optionsModule.programs;
    },
    serviceOptions() {
      return this.$store.state.optionsModule.services;
    },
    clientOptions() {
      return this.$store.state.optionsModule.clients;
    },
    providerOptions() {
      return this.$store.state.optionsModule.providers;
    },
    locationOptions() {
      return this.$store.state.optionsModule.locations;
    },
    districtOptions() {
      return this.$store.state.optionsModule.districts;
    },
    groupByOptions() {
      return this.$store.state.optionsModule.groupByFields;
    },
    showGroupBy() {
      return [RevenueReportTypes.SUMMARY].includes(this.reportType);
    },
  },
  methods: {
    getFileName(label) {
      return (
        label +
        " - " +
        formatDate(this.startDate) +
        " through " +
        formatDate(this.endDate) +
        ".csv"
      );
    },
    async downloadReport() {
      this.$store.commit("uxModule/setShowLoader", true);
      var response, type;

      switch (this.reportType) {
        case RevenueReportTypes.DETAILS:
          response = await this.$store.dispatch(
            "reportsModule/getRevenueDetailsReport",
            {
              start: this.startDate,
              end: this.endDate,
              programs: this.selectedPrograms,
              services: this.selectedServices,
              clients: this.selectedClients,
              providers: this.selectedProviders,
              locations: this.selectedLocations,
              districts: this.selectedDistricts,
              showZeroBalances: this.showZeroBalances,
            }
          );
          type = "RevenueDetails";
          break;

        case RevenueReportTypes.SUMMARY:
          response = await this.$store.dispatch(
            "reportsModule/getRevenueSummaryReport",
            {
              start: this.startDate,
              end: this.endDate,
              programs: this.selectedPrograms,
              services: this.selectedServices,
              clients: this.selectedClients,
              providers: this.selectedProviders,
              locations: this.selectedLocations,
              districts: this.selectedDistricts,
              showZeroBalances: this.showZeroBalances,
              groupBy: this.groupBy,
            }
          );
          type = "RevenueSummary";
          break;
      }

      if (response) {
        await downloadFile(response, this.getFileName(type));
      } else {
        this.$store.commit(
          "uxModule/setSnackbarMsg",
          "Oops! Something went wrong. Please try again later."
        );
        this.$store.commit("uxModule/setShowSnackbar", true);
      }
      this.$store.commit("uxModule/setShowLoader", false);
    },
  },
};
</script>
