import { formatCurrency, formatDate } from "../utils/format-utils";
import Language from "./Language";

export default class ServiceRate {
  serviceRateId?: number | null;
  programId?: number | null;
  programName?: string | null;
  serviceId?: number | null;
  serviceCode?: string | null;
  serviceName?: string | null;
  isBilingual?: boolean | null;
  effectiveDate?: Date | null;
  lastEffectiveDate?: Date | null;
  amount?: number | null;
  rateUnitTypeId?: number | null;
  rateUnitType?: string | null;
  languages?: Language[] | null;

  public constructor(params: ServiceRate = {} as ServiceRate) {
    let {
      serviceRateId = 0,
      programId = null,
      programName = null,
      serviceId = null,
      serviceCode = "",
      serviceName = "",
      isBilingual = false,
      effectiveDate = null,
      lastEffectiveDate = null,
      amount = 0,
      rateUnitTypeId = null,
      rateUnitType = "",
      languages = [],
    } = params;
    this.serviceRateId = serviceRateId;
    this.programId = programId;
    this.programName = programName;
    this.serviceId = serviceId;
    this.serviceCode = serviceCode;
    this.serviceName = serviceName;
    this.isBilingual = isBilingual;
    this.effectiveDate = effectiveDate;
    this.lastEffectiveDate = lastEffectiveDate;
    this.amount = amount;
    this.rateUnitTypeId = rateUnitTypeId;
    this.rateUnitType = rateUnitType;
    this.languages = languages;
  }

  get serviceText() {
    return this.serviceCode + " - " + this.serviceName;
  }

  get bilingual() {
    return this.isBilingual ? "Yes" : "No";
  }

  get formattedLanguages() {
    return this.languages ? this.languages.map((i) => i.name).join(",") : "N/A";
  }

  get formattedEffectiveDate() {
    return formatDate(this.effectiveDate);
  }

  get formattedLastEffectiveDate() {
    return this.lastEffectiveDate ? formatDate(this.lastEffectiveDate) : "N/A";
  }

  get formattedAmount() {
    return formatCurrency(this.amount);
  }
}
