<template>
  <validation-provider :rules="validations" v-slot="{ errors }">
    <v-text-field
      :value="formattedTime"
      :label="field.label"
      type="time"
      required
      @input="onChange"
      :error-messages="errors"
      color="#005c7b"
    >
    </v-text-field>
  </validation-provider>
</template>

<script>
import {
  getDateFromDisplayTime,
  formatTime24Hour,
} from "../../utils/format-utils";
export default {
  props: ["field", "value", "validations"],
  data() {
    return {
      dateValue: new Date(this.value + "Z")
    }
  },
  computed: {
    formattedTime: function () {
      return formatTime24Hour(this.dateValue);
    },
  },
  methods: {
    onChange: function (value) {
      if (value) {
        let date = getDateFromDisplayTime(value);
        this.$emit("fieldChange", date);
        return;
      } else {
        this.$emit("fieldChange", "");
      }
    },
  },
};
</script>
