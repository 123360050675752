import * as api from "../Services/api.js";
import ServiceRate from "../../objs/ServiceRate";

export const state = {
  serviceRates: [],
};

export const mutations = {
  SET_SERVICE_RATES(state, payload) {
    state.serviceRates = payload.map((item) => new ServiceRate(item));
  },
  ADD_SERVICE_RATE(state, payload) {
    state.serviceRates.push(new ServiceRate(payload));
  },
  UPDATE_SERVICE_RATE(state, payload) {
    state.serviceRates = state.serviceRates.map((item) => {
      return item.serviceRateId == payload.serviceRateId
        ? new ServiceRate(payload)
        : item;
    });
  },
  DELETE_SERVICE_RATE(state, serviceRateId) {
    state.serviceRates = state.serviceRates.filter(
      (item) => item.serviceRateId != serviceRateId
    );
  },
};

export const getters = {};

export const actions = {
  async getAllServiceRates({ commit }, data) {
    return await api
      .get("/serviceRate/getAllEffectiveServiceRates", data)
      .then((response) => {
        if (response.success) {
          commit("SET_SERVICE_RATES", response.data);
        }
        return response.success;
      });
  },
  async createServiceRate({ commit }, data) {
    return await api
      .post("/serviceRate/createServiceRate", data)
      .then((response) => {
        if (response.success) {
          commit("ADD_SERVICE_RATE", response.data);
          return "";
        }
        return response.message;
      });
  },
  async updateServiceRate({ commit }, data) {
    return await api
      .post("/serviceRate/updateServiceRate", data)
      .then((response) => {
        if (response.success) {
          commit("UPDATE_SERVICE_RATE", response.data);
          return "";
        }
        return response.message;
      });
  },
  async deleteServiceRate({ commit }, data) {
    return await api
      .postQuery("/serviceRate/deleteServiceRate", {
        serviceRateId: data.serviceRateId,
      })
      .then((response) => {
        if (response.success) {
          commit("DELETE_SERVICE_RATE", data.serviceRateId);
        }
        return response.success;
      });
  },
  async invoiceSessions({ commit }, data) {
    let failures = [];
    for await (const sessionId of data.sessionIds) {
      let response = await api.postQuery("/sessionInvoice/invoiceSession", {
        sessionId: sessionId,
        invoiceDate: data.invoiceDate,
      });
      if (!response.success) {
        failures.push(sessionId);
      } else {
        commit("sessionModule/updateSessionInvoiceDetails", response.data, {
          root: true,
        });
      }
    }

    let message =
      failures.length > 0
        ? "Error invoicing: " + failures.join()
        : "All sessions successfully invoiced.";
    return message;
  },
  async createOverpaymentInvoice({}, data) {
    return await api
      .postQuery("/sessionInvoice/createOverpaymentSessionInvoice", {
        clientId: data.clientId,
        programId: data.programId,
        serviceId: data.serviceId,
        dateOfService: data.dateOfService,
        invoiceDate: data.invoiceDate,
      })
      .then((response) => {
        return response.success;
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
