import * as api from "../Services/api.js";
import Program from "../../objs/Program";
import Service from "../../objs/Service";
import Client from "../../objs/Client";
import Provider from "../../objs/Provider";
import Payer from "../../objs/Payer";

export const state = {
  clients: [],
  providers: [],
  programs: [],
  services: [],
  languages: [],
  rateUnitTypes: [],
  payers: [],
  paymentMethods: [],
  adjustmentTypes: [],
  locations: [],
  districts: [],
  groupByFields: [],
};

export const mutations = {
  SET_CLIENTS(state, payload) {
    state.clients = payload
      .map((item) => new Client(item))
      .sort((a, b) => a.lastName.localeCompare(b.lastName));
  },
  SET_PROVIDERS(state, payload) {
    state.providers = payload
      .map((item) => new Provider(item))
      .sort((a, b) => a.lastName.localeCompare(b.lastName));
  },
  SET_PROGRAMS(state, payload) {
    state.programs = payload
      .map((item) => new Program(item))
      .sort((a, b) => a.name.localeCompare(b.name));
  },
  SET_SERVICES(state, payload) {
    state.services = payload
      .map((item) => new Service(item))
      .sort((a, b) => a.name.localeCompare(b.name));
  },
  SET_LANGUAGES(state, payload) {
    state.languages = payload;
  },
  SET_RATE_UNIT_TYPES(state, payload) {
    state.rateUnitTypes = payload;
  },
  SET_PAYERS(state, payload) {
    state.payers = payload.map((item) => new Payer(item));
  },
  SET_PAYMENT_METHODS(state, payload) {
    state.paymentMethods = payload;
  },
  SET_ADJUSTMENT_TYPES(state, payload) {
    state.adjustmentTypes = payload;
  },
  SET_LOCATIONS(state, payload) {
    state.locations = payload;
  },
  SET_DISTRICTS(state, payload) {
    state.districts = payload;
  },
  SET_GROUPBY_FIELDS(state, payload) {
    state.groupByFields = payload;
  },
};

export const getters = {};

export const actions = {
  async getAllClients({ commit }) {
    await api.get("/options/getAllClients").then((response) => {
      if (response.success) {
        commit("SET_CLIENTS", response.data);
      }
    });
  },
  async getAllProviders({ commit }) {
    await api.get("/provider/getAllProviders").then((response) => {
      if (response.success) {
        commit("SET_PROVIDERS", response.data);
      }
    });
  },
  async getBillingAndPaymentOptions({ commit }) {
    await api.get("/options/billingAndPaymentOptions").then((response) => {
      if (response.success) {
        commit("SET_PROGRAMS", response.data?.programs);
        commit("SET_SERVICES", response.data?.services);
        commit("SET_LANGUAGES", response.data?.languages);
        commit("SET_RATE_UNIT_TYPES", response.data?.rateUnitTypes);
        commit("SET_PAYERS", response.data?.payers);
        commit("SET_PAYMENT_METHODS", response.data?.paymentMethods);
        commit("SET_ADJUSTMENT_TYPES", response.data?.adjustmentTypes);
        commit("SET_LOCATIONS", response.data?.locations);
        commit("SET_DISTRICTS", response.data?.districts);
        commit("SET_GROUPBY_FIELDS", response.data.groupByFields);
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
